import apiServices from "../../Service/apiService";
import { formatDateToDDMMYY } from "../specialMethod/getBetweenDate";

export const handleGetEndDate = async (token, doctorId) => {
  try {
    const response = await apiServices.get(
      "authentication",
      "/currentSubscription/currentSubscriptionEndDate",
      {
        Authorization: "Bearer " + token,
      },
      { doctorId },
      null
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.error("error is coming from API", error);
  }
};

export const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

export const handlePayment = async (token, selectedAmount, clinicCount) => {
  try {
    const response = await apiServices.get(
      "authentication",
      "/subscriptionAmount/renew",
      {
        Authorization: "Bearer " + token,
      },
      {
        duration: selectedAmount,
        numberOfClinics: clinicCount,
      },
      null
    );
    if (response) {
      console.log(response);
      return response.data;
    }
  } catch (error) {
    console.error("error is coming from API", error);
  }
};

export const handleOrderFunction = async (amount) => {
  try {
    const response = await apiServices.post(
      "authentication",
      "/subscription/createOrder",
      {
        "Content-Type": "application/json",
      },
      {
        amount: amount,
      },
      null
    );
    if (response) {
      console.log("API Response:", response);
      return response.id;
    } else {
      console.log("No response received from the API.");
    }
  } catch (error) {
    console.error("API Request Error:", error);
  }
};

export const handleVerifyPayment = async (responses) => {
  const token = sessionStorage.getItem("token");
  try {
    const response = await apiServices.get(
      "authentication",
      "/subscription/fetchDetailsByPaymentId",
      {
        Authorization: " Bearer " + token,
      },
      {
        paymentId: responses.razorpay_payment_id,
      },
      null
    );
    if (response.status === "captured" || response.status === "authorised") {
      return response;
    }
  } catch (error) {
    console.error("error is coming from api", error);
  }
};

export const handleSavedEnd = async (
  razorpayresponse,
  selectedAmount,
  order_id,
  amount,
  date,
  data
) => {
  let newDate = new Date(date);
  if(newDate > new Date()){
    newDate.setDate(newDate.getDate() + 1);
  }else{
    newDate = new Date();
  }
  console.log("h", formatDateToDDMMYY(newDate));
  try {
    const response = await apiServices.patch(
      "authentication",
      "/currentSubscription/updateCurrentSubscriptionDetails",
      {
        "Content-Type": "application/json",
      },
      null,
      {
        orderId: order_id,
        paymentId: razorpayresponse.razorpay_payment_id,
        startDate: formatDateToDDMMYY(newDate),
        duration: selectedAmount,
        amount: amount / 100,
        doctorId: data.id,
      }
    );
    if (response.statusCode === 200) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleHistory = async (
  order_id,
  razorpayresponse,
  selectedAmount,
  amount,
  date,
  data
) => {
  let newDate = new Date(date);
  if(newDate > new Date()){
    newDate.setDate(newDate.getDate() + 1);
  }else{
    newDate = new Date();
  }
  console.log(formatDateToDDMMYY(newDate));
  try {
    const response = await apiServices.post(
      "authentication",
      "/subscriptionPaymentHistory/create",
      {
        "Content-Type": "application/json",
      },
      null,
      {
        orderId: order_id,
        paymentId: razorpayresponse.razorpay_payment_id,
        subscriptionStartDate: formatDateToDDMMYY(newDate),
        paymentDate: formatDateToDDMMYY(new Date()),
        duration: selectedAmount,
        amount: amount / 100,
        doctorId: data.id,
      }
    );
    if (response.statusCode === 201) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleOnBoardingPayment = async (token, setDuration) => {
  try {
    const response = await apiServices.get(
      "authentication",
      "/subscriptionAmount/onBoarding",
      {
        Authorization: " Bearer " + token,
      },
      {
        duration: setDuration,
      },
      null
    );
    if (response) {
      console.log(response.data);
      return response.data;
    }
  } catch (error) {
    console.error("error is coming from api", error);
  }
};

export const handleSavedEndOnBoarding = async (
  responses,
  selectedAmount,
  order_id,
  amount,
  doctorId
) => {
  console.log("n",doctorId)
  console.log(formatDateToDDMMYY(new Date()));
  try {
    const response = await apiServices.post(
      "authentication",
      "/currentSubscription/currentSubscriptionDetails",
      {
        "Content-Type": "application/json",
      },
      null,
      {
        orderId: order_id,
        paymentId: responses.razorpay_payment_id,
        startDate: formatDateToDDMMYY(new Date()),
        duration: selectedAmount,
        amount: amount / 100,
        doctorId: doctorId,
      }
    );
    if (response.statusCode === 201) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleHistoryOnBoarding = async (
  responses,
  selectedAmount,
  order_id,
  amount,
  doctorId
) => {
  console.log(formatDateToDDMMYY(new Date()));
  try {
    const response = await apiServices.post(
      "authentication",
      "/subscriptionPaymentHistory/create",
      {
        "Content-Type": "application/json",
      },
      null,
      {
        orderId: order_id,
        paymentId: responses.razorpay_payment_id,
        subscriptionStartDate: formatDateToDDMMYY(new Date()),
        paymentDate: formatDateToDDMMYY(new Date()),
        duration: selectedAmount,
        amount: amount / 100,
        doctorId: doctorId,
      }
    );
    if (response.statusCode === 201) {
      console.log(response);
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleAmountCalculateClinic = async (
  differenceInDays,
  sub_endDateFormat,
  token
) => {
  console.log("a", differenceInDays);
  try {
    const response = await apiServices.get(
      "authentication",
      "/subscriptionAmount/additionalClinic",
      {
        Authorization: " Bearer " + token,
      },
      {
        remainingDays: differenceInDays,
      },
      null
    );
    if (response) {
      console.log(response.data);
      return response.data;
    }
  } catch (error) {
    console.error("error is coming from api", error);
  }
};