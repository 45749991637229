import { toast } from "react-toastify";
import apiServices from "../../../../Service/apiService";

export const useHandleSubmit = (setState, toggleState, formData, varifyEmail) => {
    const doctorStringInformation = formData.doctors.map(
        (doctor) => `${doctor.degree} ${doctor.college} ${doctor.year}`
      );
    const handleSubmit = async () => {
        try {
          toggleState("loadingSpinner", true, setState)
          const response = await apiServices.post(
            "authentication",
            "/home/register",
            null,
            null,
            {
              firstName: formData.firstName,
              lastName: formData.lastName,
              degree: doctorStringInformation,
              specialization: formData.specialization,
              experience: formData.experience,
              research_journal: formData.research_journal,
              citations: formData.citations,
              achievements: formData.achievements,
              licenceNumber: formData.licenceNumber,
              contact: formData.contact,
              email: varifyEmail || formData.email,
              password: formData.password,
            }
          );
    
          if (response.statusCode === 201) {
            const getDoctorId = response.data?.id;
            toggleState("doctorId", getDoctorId, setState)
            setState(prevState => ({
                ...prevState,
                doctorId: getDoctorId
            }));
            await handleImageUpload(getDoctorId);
            return getDoctorId;
          }
        } catch (error) {
          if (error.response.data.statusCode === 500) {
            toast.error(error.response.data.message, {
              position: "top-center",
            });
          }
        } finally {
          toggleState("loadingSpinner", false, setState)
        }
      };
      const handleImageUpload = async (doctorId) => {
        try {
          const base64String = formData.imageUpload;
    
          // Extract image type from base64 string
          const typeMatch = base64String.match(/^data:(image\/\w+);base64,/);
          if (!typeMatch) {
            throw new Error("Invalid base64 string");
          }
          const imageType = typeMatch[1].split("/")[1];
    
          // Convert base64 to Blob
          const byteCharacters = atob(base64String.split(",")[1]);
          const byteArray = new Uint8Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteArray[i] = byteCharacters.charCodeAt(i);
          }
          const blob = new Blob([byteArray], { type: `image/${imageType}` });
    
          // Create a file from the Blob
          const filename = `image.${imageType}`;
          const file = new File([blob], filename, { type: `image/${imageType}` });
    
          // Prepare image data for upload
          const imageData = new FormData();
          imageData.append("image", file);
    
          const response = await apiServices.post(
            "authentication",
            "/image",
            null,
            {
              did: doctorId,
            },
            imageData
          );
          if (response) {
            return response;
          }
        } catch (error) {
          console.error("Error is coming in api", error);
        }
      };
    return { handleSubmit };
  };