import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import apiServices from "../../../Service/apiService";

export const handleSavePDF = async (componentRef, doctorId) => {
  try {
    if (!componentRef.current) return;

    const input = componentRef.current;
    const pdf = new jsPDF("p", "mm", "a4");

    // Capture screenshot and compress
    const canvas = await html2canvas(input, {
      scale: 1, // Lower scale for compression
      useCORS: true,
    });

    const imgData = canvas.toDataURL("image/jpeg", 0.37);
    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    // Add first page
    pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    // Handle additional pages
    while (heightLeft > 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }

    // Convert to Blob
    const pdfBlob = pdf.output("blob");
    console.log("Compressed PDF size:", pdfBlob.size);

    // Prepare FormData for API submission
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("file", pdfBlob, "agreement.pdf");
    formDataToSubmit.append("doctorId", doctorId);

    // Submit to API
    const response = await apiServices.post(
      "authentication",
      "/doctorTermAndCondition/create",
      null,
      { doctorId },
      formDataToSubmit
    );

    if (response) {
      console.log("Upload successful", response.data);
    } else {
      console.error("Failed to upload PDF:", response.status);
    }
  } catch (error) {
    console.error("Error uploading PDF:", error.message);
  }
};
