import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// Authentication Section
import Login from "../component/auth/login";
import RegisterDoctor from "../component/auth/doctorRegistration/registartion";
import VarifyEmail from "../component/auth/varifyEmail";
import VarifyOTP from "../component/auth/validateOtp";
import RegenrateOTP from "../component/auth/regenrateOtp";

// ABHA Creation page :------

import LandingCreationPage from "../component/abhaCreation";
import AadharAuth from "../component/abhaCreation/byAdharCard/aadharAuth";
import OTPAuth from "../component/abhaCreation/byAdharCard/otpAuthentication"
// Page Section
import Dashboard from "../component/page/dashBoard/dashboard";
import Clinic from "../component/page/clinics/clinic";

import Account from "../component/page/account/account";
import Help from "../component/page/help/help";
import Profile from "../component/page/profile/profile";
// Appointment section
import Appointment from "../component/page/appointment/appointment";

// Upload patinet Document by dynamic routing;
import UploadPatientDocument from "../component/page/document/uploadPriscription/priscriptionUploaded";

import PrivateRoute from "./privateRoute";
// Public Routing
import PublicRoute from "./publicRoute";
// if route not match on specific path then call not found component ;
import NotFound from "../component/page/notFound";
import { Provider } from "react-redux";
import store from "../component/redux/store/configureStore";
import SendOtp from "../component/auth/forgotpassword/sendOtp";
import ForgetPassword from "../component/auth/forgotpassword/forgetPassword";
import OtpCheck from "../component/auth/forgotpassword/otpCheck";


const AccessComponent = () => {
  return (
    <Provider store={store}>
    
      <Router>
        <Routes>
          {/* Public Route */}
          <Route path="/" element={<PublicRoute />}>
            <Route path="/signup" element={<VarifyEmail />} />
            <Route path="/validateOTP" element={<VarifyOTP />} />
            <Route path="/regenrateOtp" element={<RegenrateOTP />} />
            <Route path="/" element={<Login />} />
            <Route path="/forgotNewPassword" element={<ForgetPassword />} />
            <Route path="/forgetPassword" element={<SendOtp />} />
            <Route path="/forgotValidateOTP" element={<OtpCheck />} />
            <Route path="/registerdoctor" element={<RegisterDoctor />} />
            <Route path="*" element={<NotFound />} />

            {/* ABHA Creation */}
            <Route path="/register" element={<LandingCreationPage/>}/>
            <Route path="/register/aadhaar" element={<AadharAuth/>}/>
            <Route path="/register/otpAuth" element={<OTPAuth/>}/>
          </Route>
          {/* Private Route */}
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/clinic" element={<Clinic />} />
            <Route path="/appointment/:clinicId" element={<Appointment/>}/>
            <Route path="/appointment" element={<Appointment />} />
            {/* Upload patient document by  dynamic routing  */}
            <Route
              path="/uploadDocument/:id"
              element={<UploadPatientDocument />}
            />

            <Route path="/account" element={<Account />} />
            <Route path="/help" element={<Help />} />
            <Route path="/profile" element={<Profile />} />
      
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
};
export default AccessComponent;
