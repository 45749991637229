import React, { useState } from "react";
import Header from "./header";
import DoctorPersonalDetails from "./personalDetailsForm";
import DoctorPracticeForm from "./doctorPracticeForm";
import SucessPopup from "./successPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import Term from "../../page/term&condition/term";
import { toggleState } from "../../specialMethod/toggleState";

const RegisterDoctor = () => {
  const [state, setState] = useState({
    currentPage: 1,
    showNewComponent: false,
  });
  const location = useLocation();
  const varifyEmail = location.state?.getEmail || "";

  const [formData, setFormData] = useState({
    imageUpload: null,
    firstName: "Dr.",
    lastName: "",
    contact: "",
    email: varifyEmail || "",
    password: "",
    confirm_Password: "",
    doctors: [{ degree: "", college: "", year: "" }],
    specialization: [""],
    achievements: [""],
    experience: "",
    licenceNumber: "",
    research_journal: [""],
    citations: [""],
  });
  const nextPage = (values) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    toggleState("currentPage", state.currentPage + 1, setState)
  };

  const previousPage = (values) => {
    setFormData((prevData) => ({ ...prevData, ...values }));
    toggleState("currentPage", state.currentPage - 1, setState)
  };

  const handleTermPage=()=>{
    toggleState("showNewComponent", true, setState)
  }

  return (
    <> {state.showNewComponent ? (
      <Term formData={formData} varifyEmail={varifyEmail} />
    ) : (
      <div>

        <Header currentPage={state.currentPage} />
        {state.currentPage === 1 && (
          <DoctorPersonalDetails
            onNext={nextPage}
            formData={formData}
            setFormData={setFormData}
          />
        )}
        ;
        {state.currentPage === 2 && (
          <DoctorPracticeForm
            onNext={nextPage}
            onPrev={previousPage}
            formData={formData}
          />
        )}
        {state.currentPage === 3 && (
          <>
            <SucessPopup
              onSubmit={handleTermPage}
              onPrev={previousPage}
              formData={formData}
              imageData={formData.imageUpload}
            />
          </>
        )}
        <ToastContainer /></div>)}
    </>
  );
};
export default RegisterDoctor;
