import React, { useEffect, useMemo, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../loading/spinner";
import useData from "../../redux/customHook/useData";
import {
  convertTo24Hour,
  formatTime,
} from "../../specialMethod/getBetweenDate";
import { toggleState } from "../../specialMethod/toggleState";
import { InputField, SelectField, useHandleDoctorSchedule, useHandleshortDaySchedule } from "./dashboard_function/addSchedule_function";

const AddScheduleForDoctor = ({ reset, updatedSchedule }) => {
  const [state, setState] = useState({
    loadingSpinner: false,
    timeSlots: [],
    checkSchedule: [],
    collectScheduleData: [],
    short: false,
    filteredEndTimes: [],
  });
  const { data } = useData();
  const {handleDoctorSchedule}= useHandleDoctorSchedule(updatedSchedule,toggleState,setState, reset, toast);
  const {handleshortDaySchedule}= useHandleshortDaySchedule(updatedSchedule,toggleState,setState, reset, toast);
  const formik = useFormik({
    initialValues: {
      purpose: "",
      clinicName: "",
      startTime: "12:00:00" || "",
      endTime: "12:00:00" || "",
      stDate: "",
      endDate: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      purpose: Yup.string().required("Purpose is required"),
      clinicName: Yup.string().required("clinicName is required"),
      startTime: Yup.string().required("Starttime is required"),
      endTime: Yup.string().required("endTime is required"),
      stDate: Yup.string().required("startDate is required"),
      endDate: Yup.string().required("endDate is required"),
    }),
    onSubmit: (values) => {
      if (!state.short) {
        handleDoctorSchedule(values);
      } else {
        handleshortDaySchedule(values);
      }
    },
  });
  const timeSlots = useMemo(() => {
    const slots = [];
    const start = new Date(`1970-01-01T00:00:00`);
    const end = new Date(`1970-01-01T23:45:00`);
    while (start <= end) {
      slots.push(formatTime(start));
      start.setMinutes(start.getMinutes() + 15);
    }
    return slots;
  }, []);
  
  useEffect(() => {
    toggleState("timeSlots", timeSlots, setState);
  }, [timeSlots]);
  // Set initial time slots
  useEffect(() => {
    toggleState("timeSlots", timeSlots, setState);
    toggleState("filteredEndTimes", timeSlots, setState);
  }, [timeSlots]);

  // Filter End Time based on Start Time
  useEffect(() => {
    const { startTime } = formik.values;
    if (startTime) {
      const startIndex = state.timeSlots.findIndex((time) => time === startTime);
      const filteredTimes = state.timeSlots.slice(startIndex + 1); // Include only times after Start Time
      toggleState("filteredEndTimes", filteredTimes, setState);
    } else {
      toggleState("filteredEndTimes", state.timeSlots, setState); // Reset if no Start Time selected
    }
  }, [formik.values.startTime, state.timeSlots]);
  
  useEffect(() => {
    if (data) {
      toggleState("loadingSpinner", false, setState);
      toggleState("collectScheduleData", data.docIntr || [], setState);
    }
  }, [data]);
  
  useEffect(() => {
    const { startTime, endTime, stDate, endDate } = formik.values;
    if (startTime && endDate && stDate && endTime && state.collectScheduleData) {
      const startTimeDate = new Date(`${stDate}T${convertTo24Hour(startTime)}`);
      const endTimeDate = new Date(`${endDate}T${convertTo24Hour(endTime)}`);
      // Use `some` to check overlapping schedules and avoid unnecessary filtering
      const hasConflict = state.collectScheduleData.some((clinic) => {
        const clinicStartTime = new Date(`${clinic.stDate}T${clinic.startTime}`);
        const clinicEndTime = new Date(`${clinic.endDate}T${clinic.endTime}`);
        return startTimeDate < clinicEndTime && endTimeDate > clinicStartTime;
      });
      // Only update state if there's an actual change
      if (state.checkSchedule !== hasConflict) {
        toggleState("checkSchedule", hasConflict, setState);
      }
    }
  }, [
    formik.values.startTime,
    formik.values.endTime,
    formik.values.endDate,
    formik.values.stDate,
    state.collectScheduleData, // Ensure this triggers when `collectScheduleData` updates
  ]);
  const fields = [
    {
      component: InputField,
      label: "Purpose",
      type: "text",
      name: "purpose",
      placeholder: "Purpose",
    },
    {
      component: InputField,
      label: "Location",
      type: "text",
      name: "clinicName",
      placeholder: "Location",
    },
    {
      component: SelectField,
      label: "Start Time",
      name: "startTime",
      options: state.timeSlots,
    },
    {
      component: SelectField,
      label: "End Time",
      name: "endTime",
      options:state.filteredEndTimes,
    },
    {
      component: InputField,
      label: "From",
      type: "date",
      name: "stDate",
    },
    {
      component: InputField,
      label: "To",
      type: "date",
      name: "endDate",
    },
  ];
  return (
    <>
      <div
        id="login-popup"
        tabIndex="-1"
        className="bg-black/50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 h-full flex items-center justify-center"
      >
        <div className="relative p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow">
            {/* Close Button */}
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center"
              onClick={reset}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="#c6c7c7"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close popup</span>
            </button>

            {/* Form Header */}
            <div className="p-5 text-center">
              <p className="mt-2 text-2xl font-semibold leading-5 text-slate-900">
                Doctor Schedule
              </p>
            </div>

            {/* Form */}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
              className="p-5"
            >
              <div className="grid grid-cols-2 gap-6">
                {fields.map((field, index) => {
                  const FieldComponent = field.component;
                  return (
                    <FieldComponent
                      key={index}
                      label={field.label}
                      type={field.type}
                      name={field.name}
                      value={formik.values[field.name]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={field.placeholder}
                      options={field.options}
                      error={formik.touched[field.name] && formik.errors[field.name]}
                      
                    />
                  );
                })}
                <div className="col-span-2 sm:col-span-1 pl-10">
                  <input
                    type="checkbox"
                    name="modePayment"
                    checked={state.short}
                    onChange={() => toggleState("short", !state.short, setState)}
                    className="form-radio h-4 w-4 text-blue-600"
                  />
                  <span className="text-left pl-3 pb-2 text-md">Short Event</span>
                </div>
              </div>

              {/* Schedule Check */}
              {state.checkSchedule && (
                <p style={{ color: "red" }} className="text-center p-2">
                  Schedule Already Booked on this time
                </p>
              )}

              {/* Submit Button */}
              <div className="mt-6">
                <button
                  type="submit"
                  disabled={state.checkSchedule}
                  className={`w-full bg-green-500 hover:bg-blue-600 ${
                    state.checkSchedule ? "opacity-50 cursor-not-allowed" : ""
                  } text-white font-medium py-3 rounded-lg focus:outline-none`}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Toast and Spinner */}
      <ToastContainer />
      {state.loadingSpinner && <Spinner />}
    </>
  );
};

export default AddScheduleForDoctor;
