import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {  toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiServices from "../../../Service/apiService";
import Spinner from "../../../component/page/loading/spinner";


const SendOtp = () => {
  const [loadingSpinner, setloadingSpinner] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required("please enter email"),
    }),
    onSubmit: (values) => {
        handleIsValid(values)
    },
  });

  const handleIsValid = async (values)=>{
    try {
      const response = await apiServices.get(
        "authentication",
        "/home/checkEmailNoExist",
        null,
        {  email: values.email },
        null
      );

      if (response?.data === true) {
        varifyEmail(values)
      } else {
        formik.setFieldError("email", "This email is not register");
      }
    } catch (error) {
      toast.error("Error checking email. Please try again.");
    }
  }

  const getEmail = formik.values.email;

  const varifyEmail = async (values) => {
    try {
      setloadingSpinner(true);
      const response = await apiServices.post(
        "authentication",
        "/email/otp",
        null,
        {
          email: values.email,
        },
        null,
      );
      console.log(response)
      if (response.statusCode === 200) {
        navigate("/forgotValidateOTP", { state: { getEmail } });
      } 
    } catch (error) {
      throw error;
    } finally {
      setloadingSpinner(false);
    }
  };
  return (
    <>
      <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col items-center justify-between">
        <div className="max-w-screen-xl m-0 sm:m-10 md:shadow sm:rounded-lg flex justify-center flex-1 ">
          <div className="lg:flex-1 md:bg-white text-center flex flex-col justify-center items-center">
            {/* Doc-Aid Logo */}
            <div className="w-full flex justify-center items-center">
              <div className="lg:w-3/4 mb-24 md:mb-0">
                <img alt="img" className="w-[350px] xl:w-full lg:w-auto mb-24 md:mb-0" src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/doc-aid.png" />
              </div>
            </div>
            {/* for mobile screen User Interface */}
            <div
              className="absolute bottom-0  lg:hidden w-full py-10" 
              style={{
                backgroundColor: "rgb(237, 231, 242)",
                borderTopLeftRadius: "40px",
                borderTopRightRadius: "40px",
              }}
            >
              <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-16 flex justify-center items-center">
                {/* input box */}
                <div className="w-full mt-4rem">
                  <div className="my-1 border-b text-center">
                    <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2"></div>
                  </div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                    }}
                  >
                    <div className="mx-auto max-w-xs">
                      <input
                        className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-500 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                        type="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder="Email"
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <p style={{ color: "red" }}>{formik.errors.email}</p>
                      ) : null}

                      <button
                        type="submit"
                        className="mt-5 tracking-wide font-semibold text-white-500 w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                        style={{ backgroundColor: "rgb(7, 160, 165)" }}
                      >
                        <svg
                          className="w-6 h-6 -ml-2"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                          <circle cx="8.5" cy="7" r="4" />
                          <path d="M20 8v6M23 11h-6" />
                        </svg>
                        &nbsp;
                        <span className="">Send OTP</span>
                      </button>
                      <p className="mt-6 text-md text-gray-900 text-center">
                        Already have an Account &nbsp;
                        <Link
                          to="/"
                          className=" border-gray-500 text-lg font-semibold"
                        >
                          Login
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block lg:w-1/2 xl:w-5/12 p-6 sm:p-12">
            {/* input box */}
            <div className="mt-12 flex flex-col items-center">
              <div className="w-full flex-1 mt-8">
                <div className="my-12 border-b text-center">
                  <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-gray-800 transform translate-y-1/2"></div>
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  <div className="mx-auto max-w-xs">
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-500 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                      type="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      placeholder="Email"
                    />
                    {formik.touched.email || formik.errors.email ? (
                      <p style={{ color: "red" }}>{formik.errors.email}</p>
                    ) : null}

                    <button
                      type="submit"
                      className="mt-5 tracking-wide font-semibold text-white-500 w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                      style={{ backgroundColor: "rgb(7, 160, 165)" }}
                    >
                      <svg
                        className="w-6 h-6 -ml-2"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                        <circle cx="8.5" cy="7" r="4" />
                        <path d="M20 8v6M23 11h-6" />
                      </svg>
                      &nbsp;
                      <span className="ml-">Send OTP</span>
                    </button>
                    <p className="mt-6 text-md text-gray-900 text-center">
                      Already have an Account &nbsp;
                      <Link
                        to="/"
                        className=" border-gray-500 text-lg font-semibold"
                      >
                        Login
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loadingSpinner && <Spinner />}
      <ToastContainer />
    </>
  );
};
export default SendOtp;
