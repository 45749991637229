import React, { useEffect, useState } from 'react'

const DentistImages = ({xrayImages}) => {
    const defaultImageSrc = "path_to_default_image_or_logo.png"; //
  const [selectedImages, setSelectedImages] = useState([]);

  console.log(selectedImages)
  // Handle image selection
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to array
    const newImages = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImages((prev) => [...prev, reader.result]); // Add base64 image to state
      };
      reader.readAsDataURL(file);
    });
  };
  useEffect(() => {
    xrayImages(selectedImages);
  }, [selectedImages, xrayImages]); // Depend on selectedImages
  // Remove selected image
  const removeImage = (index) => {
    setSelectedImages((prev) => prev.filter((_, i) => i !== index));
  };
  return (
    <>             <div className="rounded-lg border border-gray-400 p-4">
    <div className="text-xl mb-4">X-ray Report</div>

    {/* Image Preview Section */}
    <div className="flex flex-wrap gap-4">
      {selectedImages.length > 0 ? (
        selectedImages.map((img, index) => (
          <div key={index} className="relative">
            <img
              className="h-36 w-36 object-cover rounded"
              src={img || defaultImageSrc}
              alt={`Uploaded ${index}`}
            />
            <button
              className="absolute top-1 right-1 bg-red-500 text-white rounded-full px-2 py-1 text-xs"
              onClick={() => removeImage(index)}
            >
              X
            </button>
          </div>
        ))
      ) : (
        <p className="text-gray-500">No images selected</p>
      )}
    </div>

    {/* File Input */}
    <label className="block mt-4">
      <span className="sr-only">Upload X-Ray Reports</span>
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleImageChange}
        className="hidden"
        id="fileInput"
      />
      <label
        htmlFor="fileInput"
        className="file-button bg-blue-500 text-white px-4 py-2 rounded-full cursor-pointer hover:bg-blue-700"
      >
        Select Images
      </label>
    </label>
  </div></>
  )
}

export default DentistImages