// components/CustomToolbar.js
import React, { useEffect, useState } from "react";
import "../../../App.css";
import { FaDotCircle } from "react-icons/fa";
const CustomToolbar = (toolbar) => {
  const [state, setState] = useState({
    activeViewWeek: "",
    activeViewDay: "",
    activeViewMonth: "",
  });
  const [currentRange, setCurrentRange] = useState("");
  const calculateDateRange = () => {
    const activeDate = new Date(toolbar.date);

    if (toolbar.view === "week") {
      const startOfWeek = new Date(
        activeDate.setDate(activeDate.getDate() - activeDate.getDay())
      );
      const endOfWeek = new Date(
        activeDate.setDate(startOfWeek.getDate() + 6)
      );

      setCurrentRange(
        `${startOfWeek.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
        })} - ${endOfWeek.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })}`
      );
    } else if (toolbar.view === "month") {
      const month = toolbar.date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
      });
      setCurrentRange(month);
    } else if (toolbar.view === "day") {
      setCurrentRange(
        toolbar.date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      );
    }
  };

  useEffect(() => {
    calculateDateRange();
  }, [toolbar.date, toolbar.view]);

  const toggleState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const [activeViewDay, setActiveViewDay] = useState("");
  const [activeViewMonth, setActiveViewMonth] = useState("");
  const goToBack = () => {
    toolbar.onNavigate("PREV");
  };

  const goToNext = () => {
    toolbar.onNavigate("NEXT");
  };

  const goToToday = () => {
    toolbar.onNavigate("TODAY");
  };
  const weekView = (view) => {
    toggleState("activeViewWeek", view);
    toolbar.onView("week");
    toggleState("activeViewDay", "");
    toggleState("activeViewMonth", "");
  };
  const dayView = (view) => {
    toggleState("activeViewDay", view);
    toolbar.onView("day");
    toggleState("activeViewWeek", "");
    toggleState("activeViewMonth", "");
  };
  const monthView = (view) => {
    toggleState("activeViewMonth", view);
    toolbar.onView("agenda");
    toggleState("activeViewDay", "");
    toggleState("activeViewWeek", "");
  };
  useEffect(() => {
    weekView();
    toggleState("activeViewWeek", "week");
  }, []);

  return (
    <>
      <div className="flex justify-between items-center">
  {/* Left Section */}
  <div
    className="font-bold text-gray-800 mb-4 md:text-xl"
    style={{ width: "150px", height: "50px", padding: "10px" }}
  >
    <div>
      <button
        type="button"
        className={`py-2 px-2 mb-2 text-sm font-medium rounded-lg ${state.activeViewWeek === "week"
          ? "text-white border-gray-700 bg-gray-700"
          : "text-black bg-gray-200 border-gray-400"
          } focus:outline-none border focus:z-10 mr-2`}
        onClick={() => weekView("week")}
      >
        Weekly
      </button>
      <button
        type="button"
        className={`py-2 px-2 mb-2 text-sm font-medium rounded-lg ${state.activeViewDay === "day"
          ? "text-white border-gray-700 bg-gray-700"
          : "text-black bg-gray-200 border-gray-400"
          } focus:outline-none border focus:z-10 mr-2`}
        onClick={() => dayView("day")}
      >
        Day
      </button>
    </div>
  </div>
  <div className="font-bold text-lg mr-5">{currentRange}</div>
  {/* Right Section */}
  <div>
    <div className="flex items-center space-x-4">
      <button
        type="button"
        onClick={goToBack}
        className="flex items-center justify-center text-black hover:bg-gray-200"
      >
         <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
    </svg>
      </button>
      <button
        type="button"
        onClick={goToToday}
        className="flex text-gray-700 hover:bg-gray-200"
      >
        <FaDotCircle />
      </button>
      <button
        type="button"
        onClick={goToNext}
        className="flex text-black hover:bg-gray-200"
      >
       <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
    </svg>
      </button>
    </div>
  </div>
</div>

    </>
  );
};

export default CustomToolbar;