import React, { useEffect, useState } from "react";
import NavbarWrapper from "../../../widgets/navbarWrapper";
import VarifyPatientModal from "./varifyPatientModal";
import apiServices from "../../../Service/apiService";
import PrintPriscription from "../document/priscriptionDocument";
import BookShowAppointment from "../document/showBookAppointment";
import useData from "../../redux/customHook/useData";
import { Link } from "react-router-dom";
import PaymentMode from "./paymentMode";
import { useParams } from "react-router-dom";
import Spinner from "../loading/spinner";
import useAppointmentData from "../../redux/customHook/useAppointmentData";
import EditScheduleAppointment from "./editScheduleAppointment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteScheduleAppointment from "./deleteScheduleAppointment";
import SettleDuePayment from "./settleDuePayment";
import { useDispatch } from "react-redux";
import {
  upatePatientVisited,
  updatePatientNonVisited,
} from "../../redux/features/allAppointment/appointmentSlice";
import DueModal from "./dueModal";
import usePrescriptionPhoto from "../../redux/customHook/usePrescriptionImage";
import { handleGetEndDate } from "../../razorrpay/razorpay";
import PrescriptionOption from "../document/prescriptionOption";
import UnpaidtoPaid from "./dentistPayment/unpaidToPaid";

const ListOfAppointment = () => {
  const dispatch = useDispatch();
  const { clinicId } = useParams();
  const id = parseInt(clinicId, 10);
  const { prescriptionPhotoURLs, loadingSpinner } = usePrescriptionPhoto();

  const [state, setState] = useState({
    verifyPatient: false,
    getAppointData: [],
    showPriscription: false,
    showAppointmentBook: false,
    getPatientInformation: null,
    getPatntPrsInf: null,
    selectClinicFromDropdwn: false,
    settleDuePayment: false,
    clickableClinicName: null,
    patientDetails: [],
    loadinSpinner: true,
    ClinicI: null,
    allPatient: true,
    editScheduleDetail: null,
    editSchedule: false,
    deleteScheduleDetail: null,
    deleteSchedule: false,
    getClinicDetails: [],
    paymentSettled: false,
    searchAppointment: [],
    searchTerm: "",
    lastAppointment: {},
    dueLeft: false,
    daysLeft: "",
    prescriptionPhotoURLs: prescriptionPhotoURLs,
  });

  const handleSearchChange = (event) =>
    toggleState("searchTerm", event.target.value);

  const toggleState = (key, value) =>
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const { appointmentdata } = useAppointmentData(selectedDate);
  const { data } = useData();
  useEffect(() => {
    const fetchEndDate = async () => {
      if (data && data.clinicDtos) {
        const activeClinics = data.clinicDtos.filter(
          (clinic) => clinic.clinicStatus === "Active"
        );
        toggleState("getClinicDetails", activeClinics || []);
        const endDate = await handleGetEndDate(token, data?.id);
        if (endDate) {
          const currentDate = new Date();
          const expireDate = new Date(endDate); // Assuming endDate is a string that can be converted to a Date object

          currentDate.setHours(0, 0, 0, 0);
          expireDate.setHours(0, 0, 0, 0);

          const leftDays = (expireDate - currentDate) / (1000 * 60 * 60 * 24);

          if (leftDays <= 7) {
            toggleState("dueLeft", true);
            toggleState("daysLeft", leftDays);
          }
        }
      }
    };

    fetchEndDate();
  }, [data]);

  useEffect(() => {
    if (state.getAppointData) {
      const sortedAppointments = [...state.getAppointData].sort((a, b) =>
        a.appointmentTime.localeCompare(b.appointmentTime)
      );
      const searchTerm = state.searchTerm.toLowerCase();

      const filteredMessages = sortedAppointments.filter((message) =>
        message.name?.toLowerCase().includes(searchTerm)
      );

      const filteredContactMessages = sortedAppointments.filter((message) =>
        message.contact?.toLowerCase().includes(searchTerm)
      );

      // Combine the two filtered results (removing duplicates)
      const combinedFilteredMessages = [
        ...new Set([...filteredMessages, ...filteredContactMessages]),
      ];

      // Use combinedFilteredMessages in toggleState
      toggleState("searchAppointment", combinedFilteredMessages);
    }
  }, [state.getAppointData, state.searchTerm]);

  useEffect(() => {
    if (data && clinicId && data.clinicDtos) {
      handleClinicModal(clinicId);
      const activeClinics = data.clinicDtos.filter(
        (clinic) => clinic.clinicStatus === "Active"
      );
      const clinicData = activeClinics.find((clinic) => clinic.id === id);
      toggleState("clickableClinicName", clinicData?.location);
    }
  }, [clinicId]);
  useEffect(()=>{
    if(selectedDate != new Date().toISOString().split("T")[0]){
      toggleState("clickableClinicName", state.getClinicDetails[0].location);
      handleClinicModal(state.getClinicDetails[0].id)
    }
  },[selectedDate])

  const settlePaymentHandler = () => {
    toggleState("paymentSettled", true);
  };
  const closeHandleSettleDuePayment = () => {
    toggleState("paymentSettled", false);
  };
  const handleDateChange = (event) => setSelectedDate(event.target.value);

  const handleAppointmentModal = () => {
    toggleState("verifyPatient", true);
  };
  const handleClosePatientModal = () => {
    toggleState("verifyPatient", false);
  };
  const token = sessionStorage.getItem("token");
  const showAllPatient = async () => {
    toggleState("allPatient", true);
    toggleState("loadinSpinner", true);
    try {
      const response = await appointmentdata;
      if (response) {
        toggleState("getAppointData", response.data);
        toggleState("selectClinicFromDropdwn", false);
        toggleState("loadinSpinner", false);
      }
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        toggleState("getAppointData", []);
        toggleState("selectClinicFromDropdwn", false);
      }
    }
  };
  useEffect(() => {
    showAllPatient();
  }, [appointmentdata]);
  const handleClinicModal = async (values) => {
    toggleState("ClinicI", values);
    toggleState("loadinSpinner", true);
    toggleState("allPatient", false);
    toggleState("getAppointData", []);
    try {
      const response = await apiServices.get(
        "authentication",
        "/clinic/betweenDate",
        null,
        {
          clinicId: values,
          startDate: selectedDate,
          endDate: selectedDate,
        },
        null
      );
      if (response.statusCode === 200) {
        toggleState("getAppointData", response.data);
        toggleState("selectClinicFromDropdwn", false);
      }
    } catch (error) {
      if (error.response.data.statusCode === 404) {
        toggleState("getAppointData", []);
        toggleState("selectClinicFromDropdwn", false);
      }
    } finally {
      toggleState("loadinSpinner", false);
    }
  };

  const handlePriscriptionModal = () => {
    toggleState("showPriscription", true);
  };

  const closePriscrptnModal = () => {
    toggleState("showPriscription", false);
  };
  const handleShowPatientAppointment = () => {
    toggleState("showAppointmentBook", true);
  };
  const closeShowPatientAppointment = () => {
    toggleState("showAppointmentBook", false);
  };
  const handleSeelctClinicFromDropDown = () => {
    toggleState("selectClinicFromDropdwn", !state.selectClinicFromDropdwn);
  };

  const handleSettleDuePayment = () => {
    toggleState("settleDuePayment", true);
  };
  const closeSettleDuePayment = async () => {
    toggleState("settleDuePayment", false);
  };

  const handleVisited = async (event, id) => {
    const isChecked = event.target.checked;
    try {
      const response = await apiServices.patch(
        "authentication",
        "/appointment/setAppointmentVisitStatus",
        {
          Authorization: " Bearer " + token,
        },
        {
          appintmentId: id,
          checked: isChecked,
        },
        null
      );
      if (response) {
        if (isChecked) {
          dispatch(upatePatientVisited({ id: id }));
        } else {
          dispatch(updatePatientNonVisited({ id: id }));
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <NavbarWrapper showNavbar={true} />
      <div className="bg-white border-gray-200 py-2.5 dark:bg-white">
        <div className="flex flex-wrap md:mt-20 mt-0 items-center justify-between max-w-screen-xl px-4 mx-auto">
          <p className="text-2xl mb-4 font-bold text-gray-900 montserrat">
            Your Appointment
          </p>
          <div className="flex items-center lg:order-2">
            <div className="hidden mt-4 mr-4 sm:inline-block">
              <span></span>
            </div>
            <button
              onClick={() => {
                handleAppointmentModal(true);
              }}
              className="text-white bg-purple-700  focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0    focus:outline-none dark:focus:ring-purple-800"
              style={{ backgroundColor: "rgb(4,106,110)" }}
            >
              Book Appointment
            </button>

            {state.verifyPatient ? (
              <>
                <VarifyPatientModal
                  reset={handleClosePatientModal}
                />
              </>
            ) : null}
          </div>
          <div
            className="items-center justify-between w-full lg:flex lg:w-auto lg:order-1"
            id="mobile-menu-2"
          >
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              <form className="flex flex-col md:flex-row gap-3">
                <div className="flex">
                  <input
                    type="text"
                    placeholder="Patient Search"
                    value={state.searchTerm}
                    onChange={handleSearchChange}
                    className="w-full md:w-80 px-3 h-10 rounded-l border-2 border-gray-500 focus:outline-none focus:border-sky-500"
                  />
                  <button
                    type="submit"
                    className=" text-white rounded-r px-2 md:px-3 py-0 md:py-1"
                    style={{ backgroundColor: "rgb(4,106,110)" }}
                  >
                    Search
                  </button>
                </div>
              </form>
            </ul>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:items-center justify-between max-w-screen-xl px-4 pt-6 mx-auto">
          <div className="flex  mb-4 lg:mb-0">
            <p className="mr-2 lg:mr-4">Choose Date:</p>
            <input
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              className="mb-2 lg:mb-0"
            />
          </div>

          <div className="flex items-center">
            <p className="pr-3">Show</p>
            <div className="relative ml-2">
              <div className="flex items-center space-x-2">
                <button
                  type="button"
                  onClick={showAllPatient}
                  className={`px-3
               ${state.allPatient ? "text-amber-800 underline" : ""} 
              py-2 text-amber-500`}
                  style={{
                    backgroundColor: "rgb(255,245,235)",
                  }}
                >
                  {" "}
                  All
                </button>
                <button
                  type="button"
                  onClick={handleSeelctClinicFromDropDown}
                  className={`flex ${
                    !state.allPatient ? "text-amber-800 underline" : ""
                  } text-amber-500 items-center justify-center rounded-lg px-4 py-2 bg-purple-700`}
                  style={{
                    backgroundColor: "rgb(255,245,235)",
                  }}
                >
                  <span>
                    {state.clickableClinicName === null || state.allPatient
                      ? "Select Clinic"
                      : state.clickableClinicName.substring(0, 12)}
                  </span>
                  <svg
                    className="w-5 h-5 ml-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 12.586l-4.293-4.293a1 1 0 0 1 1.414-1.414L10 10.758l3.879-3.879a1 1 0 1 1 1.414 1.414L10 12.586z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              {state.selectClinicFromDropdwn && (
                <div className="absolute right-3 mt-2 w-50 bg-white rounded-lg shadow-lg">
                  {state.getClinicDetails.map((value, index) => (
                    <button
                      key={index}
                      className="block w-full px-4 py-2 text-md montserrat text-gray-700 hover:bg-gray-100"
                      onClick={() => {
                        handleClinicModal(value.id);
                        toggleState("clickableClinicName", value.location);
                      }}
                    >
                      {value.location.substring(0, 12)}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 overflow-x-auto">
          <thead className="bg-gray-200">
      <tr>
        {[
          "Serial No.",
          "Visit Status",
          "Time Of Visit",
          "Name",
          "Age",
          "Status",
          "Address",
          "Guardian Name",
          "Action",
          "Edit",
        ].map((header, index) => (
          <th
            key={index}
            scope="col"
            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            {header}
          </th>
        ))}
      </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {!state.searchAppointment ? (
              <tr>
                <td colSpan="7" className="text-center py-8">
                  <div className="text-gray-800 montserrat">
                    No appointment has been booked !
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {state.searchAppointment.map((patient, index) => {
                  return (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {index + 1}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <input
                          type="checkbox"
                          name="modePayment"
                          value="cash"
                          checked={patient.appointmentvisitStatus === "VISITED"}
                          className="form-radio h-5 w-5 text-blue-600 cursorr-pointe"
                          onChange={(event) => handleVisited(event, patient.id)}
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className={`text-sm text-gray-900  `}>
                          {patient.appointmentTime}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              <button
                                onClick={() => {
                                  handleShowPatientAppointment();
                                  toggleState("getPatientInformation", patient);
                                }}
                              >
                                {patient.name}
                              </button>
                              {state.showAppointmentBook && (
                                <BookShowAppointment
                                  drName={data?.firstName +" "+ data?.lastName}
                                  patientData={state.getPatientInformation}
                                  reset={closeShowPatientAppointment}
                                  clinics={state.getClinicDetails}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {patient.age}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={`px-2 inline-flex text-sm leading-5 font-semibold rounded-full ${
                            patient.paymentStatus === "UNPAID"
                              ? "text-red-800"
                              : "text-green-500"
                          }`}
                        >
                          {patient.paymentStatus === "UNPAID" ? (
                            <button
                              type="button"
                              onClick={() => {
                                toggleState("patientDetails", patient);
                                handleSettleDuePayment();
                              }}
                            >
                              {patient.paymentStatus}
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                toggleState("patientDetails", patient);
                                settlePaymentHandler();
                              }}
                            >
                              {patient.paymentStatus}
                            </button>
                          )}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {patient.address}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {patient.guardianName}
                      </td>
                      <td className="flex px-6 py-6 whitespace-nowrap  text-sm font-medium">
                        <button
                          onClick={() => {
                            toggleState("getPatntPrsInf", patient);
                            handlePriscriptionModal();
                          }}
                        >
                          <img
                            className="h-8 w-8 rounded-full"
                            src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/prescriptionBlack.svg"
                            alt=""
                          />
                        </button>
                        {state.showPriscription ? (
                          <>
                          <PrescriptionOption  prescriptionPhotoURLs={prescriptionPhotoURLs}
                              doctorDetails={data}
                              patientData={state.getPatntPrsInf}
                              reset={closePriscrptnModal} />
                          </>
                        ) : null}
                        <Link to={`/uploadDocument/${patient.patientId}`}
                        state={{patient }}>
                          <img
                            className="md:h-8 md:w-8 h-6 w-6 sm:pt-2 pt-0 md:p-1"
                            src="https://d2sv8898xch8nu.cloudfront.net/MediaFiles/upload.png"
                            alt=""
                          />
                        </Link>
                      </td>
                      <td className="px-4 py-6 whitespace-nowrap  text-sm font-medium">
                        <div className="flex items-center space-x-2">
                          <button
                            onClick={() => {
                              toggleState("editScheduleDetail", patient);
                              toggleState("editSchedule", true);
                            }}
                          >
                          <svg className="w-6 h-6" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 19.0003H17M2.666 11.1873C2.23962 11.6147 2.00011 12.1937 2 12.7973V16.0003H5.223C5.827 16.0003 6.406 15.7603 6.833 15.3323L16.333 5.82734C16.7592 5.39993 16.9986 4.82095 16.9986 4.21734C16.9986 3.61372 16.7592 3.03474 16.333 2.60734L15.395 1.66734C15.1835 1.45569 14.9323 1.28781 14.6558 1.1733C14.3794 1.0588 14.083 0.999907 13.7838 1C13.4846 1.00009 13.1883 1.05917 12.9119 1.17385C12.6355 1.28852 12.3844 1.45656 12.173 1.66834L2.666 11.1873Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                          </button>
                          <button
                            onClick={() => {
                              toggleState("deleteScheduleDetail", patient);
                              toggleState("deleteSchedule", true);
                            }}
                          >
                            <svg
                    className="w-7 h-7 text-red-500 fill-current"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9 2a1 1 0 011 1v1h6a1 1 0 110 2h-1v11a2 2 0 01-2 2H7a2 2 0 01-2-2V6H4a1 1 0 110-2h6V3a1 1 0 011-1zM7 6v11h6V6H7zm2-3v1h2V3H9z" />
                  </svg>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>
      </div>
      {state.editSchedule && (
        <EditScheduleAppointment
          detail={state.editScheduleDetail}
          reset={() => {
            toggleState("editSchedule", false);
          }}
        />
      )}
      {state.deleteSchedule && (
        <DeleteScheduleAppointment
          detail={state.deleteScheduleDetail}
          reset={() => {
            toggleState("deleteSchedule", false);
          }}
        />
      )}
      {state.settleDuePayment &&  (
        <>
        {data.specialization[0].toLowerCase()!=='dentist' ? <PaymentMode
          patientDetails={state.patientDetails}
          reset={closeSettleDuePayment}
        />:<UnpaidtoPaid patientDetails={state.patientDetails}
        reset={closeSettleDuePayment}/>} 
        </>
      )}
      {state.paymentSettled && (
        <SettleDuePayment
          patientDetails={state.patientDetails}
          reset={closeHandleSettleDuePayment}
        />
      )}
      {state.loadinSpinner && <Spinner />}
      {loadingSpinner && <Spinner />}
      <ToastContainer />
      {state.dueLeft && (
        <DueModal
          reset={() => {
            toggleState("dueLeft", false);
          }}
          daysLeft={state.daysLeft}
        />
      )}
    </>
  );
};

export default ListOfAppointment;
